<template>
	<div class="stopanchorageFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.stop.portcallnumber')" :value="getPortCallNumber"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.stop.num')" :value="getStopNumber"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.stop.portname')" :value="getPortName"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.stop.vesselname')" :value="getVesselName"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'stopanchorageFormHeader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'stopanchorage'
		};
	},
	computed: {
		getPortCallNumber() {
			return this.model && this.model.portcallnumber ? this.model.portcallnumber : '';
		},
		getStopNumber() {
			return this.model && this.model.num ? this.model.num : '';
		},
		getPortName() {
			return this.model && this.model.portname ? this.model.portname : '';
		},
		getVesselName() {
			return this.model && this.model.vesselname ? this.model.vesselname : '';
		}
	}
};
</script>
